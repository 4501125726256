import * as React from "react"
import DownloadFilePage from "@/components/DownloadFilePage"



const Index = () => (
  <>
    <DownloadFilePage
      downloadURL={`${process.env.resourceUrl}docs/manual/Glasses_Button_Functions.pdf`}
    />
  </>
)

export default Index